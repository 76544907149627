<div 
    class="next-overlay-panel"
    [testIdDataAttr]="OverlayPanelTestId.MAIN">
    <link-button [iconType]="NextIcon.EXCLAMATION"
                [iconColor]="IconColor.BRAND_ACTION"
                [iconSize]="IconSizeModifier.SMALL"
                [iconPosition]="LinkButtonPosition.LEFT"
                [label]="labelButton"
                (clickEvt)="panelInfo.toggle($event)"
                [testIdDataAttr]="OverlayPanelTestId.BUTTON"></link-button>
    <p-overlayPanel #panelInfo [testIdDataAttr]="OverlayPanelTestId.PANEL">
        <div class="flex flex-column gap-3 w-25rem">
            <span class="font-medium text-900 block mb-2 next-overlay-panel__title-criteria">{{title || (titleKey! | translate)}}</span>
            <div *ngFor="let info of data" class="flex align-items-center gap-2 next-overlay-panel__container">
                <span class="font-medium next-overlay-panel__key">{{ info[config[OverlayPanelConfigProps.KEY_PROP]] }}</span>
                <span class="text-sm text-color-secondary" [title]="info[config[OverlayPanelConfigProps.DESCRIPTION_PROP]]">
                    <next-badge [colorType]="colorTypeMap[info[ColorTypeProp]]" />
                    @if(config[OverlayPanelConfigProps.CURRENCY_PROP]) {
                        {{ info[config[OverlayPanelConfigProps.VALUE_PROP]] | nextFormatCurrency: info[config[OverlayPanelConfigProps.CURRENCY_PROP]] }}
                    } @else {
                        {{ info[config[OverlayPanelConfigProps.VALUE_PROP]] }}
                    }
                </span> 
            </div>
        </div>
    </p-overlayPanel>
</div>