import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { OverlayPanelConfigProps, OverlayPanelTestId } from '../enums/next.overlay-panel.enum';

@Component({
  selector: 'next-overlay-panel',
  templateUrl: './next-overlay-panel.component.html',
  styleUrl: './next-overlay-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextOverlayPanelComponent {
  @Input() title?: string;
  @Input() titleKey?: string;
  @Input() data: any;
  @Input() config: any;
  @Input() colorTypeMap: any;
  @Input() ColorTypeProp: string;
  @Input() labelButton: string;

  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly ColorType = ColorType;
  protected readonly IconColor = IconColor;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly OverlayPanelConfigProps = OverlayPanelConfigProps;
  protected readonly OverlayPanelTestId = OverlayPanelTestId;
}
