export enum OverlayPanelConfigProps {
    VALUE_PROP = 'value',
    KEY_PROP = 'key',
    CURRENCY_PROP = 'currency',
    DESCRIPTION_PROP = 'description'
}

export enum OverlayPanelTestId {
    MAIN = 'next-overlay-panel__container',
    BUTTON = 'next-overlay-panel__buttom',
    PANEL = 'next-overlay-panel__panel'
}